import { MarketingLayout } from '@/Layouts'
import { usePage } from '@inertiajs/react'

type Props = {
  status: number
}

export default function Page() {
  const { status } = usePage<Props>().props
  const title = {
    503: 'Service Unavailable',
    500: 'Server Error',
    404: 'Page Not Found',
    403: 'Forbidden'
  }[status]

  return (
    <MarketingLayout>
      <main className='mx-auto my-auto flex w-full flex-col gap-y-6 px-4 md:max-w-4xl lg:px-0'>
        <div className='mx-auto flex max-w-max flex-col justify-center gap-4'>
          <h1 className='text-9xl font-medium text-medium'>{status}</h1>
          <h2 className='text-4xl font-medium text-medium'>{title}</h2>
          <p className='mt-2 text-weak'>
            Please try again, and contact support if the problem persists.
          </p>
        </div>
      </main>
    </MarketingLayout>
  )
}
